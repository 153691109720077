<template>
    <div id="app-layout">
        <router-view v-slot="{ Component }">
            <transition name="layout" mode="out-in">
                <component :is="Component" />
            </transition>
        </router-view>
        <FastTrack v-if="isLogged" />
        <PragmaticLive v-if="showPragmaticLive" />
        <FreshChat
            v-if="freshChatConfig?.enabled"
            :data="freshChatData"
            @update:unread-count="freshChatSetMessageCount"
            @update:restore-id="sendFreshchatRestoreId"
        />
        <LiveChat v-else-if="liveChatConfig?.enabled" />
        <GameFrameController />
        <transition name="fade" mode="out-in">
            <ModalController />
        </transition>
        <ToastModule group="basic" />
        <SupportChatBadge />
        <PixelAnalytics />
        <OnboardController />
        <BackToTop />
    </div>
</template>

<script lang="ts">
import { useDataFillingForm } from "@modules/Auth/RegistrationSteps/dataFillingForm";
import { mapActions as piniaActions, mapState, storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import ModalController from "@modules/ModalController/index.vue";
import ToastModule from "@modules/Toast/ToastModule.vue";
import GameFrameController from "@modules/GameFrame/GameFrameController.vue";
import { setUtmParamsToCookies } from "@front/core/controllers/utmParams";
import { setAffiliateToCookie } from "@front/core/controllers/affiliateParam";
import DmpController from "@front/core/controllers/MetriksController/DmpController";
import PixelAnalytics from "@components/PixelAnalytics/PixelAnalytics.vue";
import BackToTop from "@components/BackToTop/BackToTop.vue";
import fav16 from "@theme/images/BrandImages/favicon/favicon_16.png";
import fav32 from "@theme/images/BrandImages/favicon/favicon_32.png";
import iosIcon152 from "@theme/images/BrandImages/pwa_icons/ios_icon_152.png";
import iosIcon167 from "@theme/images/BrandImages/pwa_icons/ios_icon_167.png";
import iosIcon180 from "@theme/images/BrandImages/pwa_icons/ios_icon_180.png";
import imageLogo from "@theme/images/BrandImages/logo.png";
import manifest from "@theme/manifest.json";

import type {
    IStaticPagesItemResource,
    IUserBalanceResource,
    IUserNotificationResource,
    IUserNotificationVarsResource,
} from "@api";
import type { Localisation } from "@api/resources/commonResources";
import { computed, defineComponent, onMounted, onServerPrefetch, onUnmounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { type Link, useHead, useSeoMeta } from "@unhead/vue";
import { defineOrganization, useSchemaOrg } from "@unhead/schema-org/vue";
import LiveChat from "@front/core/modules/LiveChat/index.thor.vue";
import FreshChat from "@modules/FreshChat/index";
import PragmaticLive from "@modules/PragmaticLive/PragmaticLive";
import FastTrack from "@modules/FastTrack/FastTrack.vue";
import SupportChatBadge from "@modules/SupportChatBadge/index.vue";
import { createServiceWorker } from "@plugins/serviceWorker";
import { withdrawCancelPopup } from "@controllers/WithdrawCancelController";
import CoveryController from "@front/core/controllers/CoveryController";
import { OnboardController } from "@modules/Onboard";
import { restrictedNotice } from "@helpers/filterNotice";
import { CUSTOM_CANONICAL_URLS_BY_HOST } from "@theme/configs/canonicalUrls";
import { CONFIG_SCHEME_ORG_BY_COUNTRY, SCHEME_ORG_DESCRIPTION_BY_COUNTRY } from "@theme/configs/configSchemaOrg";
import config from "@theme/configs/config";
import { openCashbox } from "@helpers/CashboxHelper";
import { generateStaticRoutes } from "@router/StaticPageRouterController";
import showPwaBonus from "@helpers/showPwaBonus";
import showVerificationModal from "@helpers/showVerificationModal";
import { getFeatureFlags } from "@front/core/modules/FeatureFlagConfiguration";
import bus from "@bus";
import { useGifts } from "@store/gifts";
import { usePromo } from "@store/promo";
import { useCashbox } from "@store/cashbox";
import { useChats } from "@store/chats";
import { useLevels } from "@store/levels";
import { getCurrencyByGeo } from "@helpers/currencyByGeo";
import { useAchievements } from "@modules/Quests";
import { useContextStore } from "@store/context";
import { useCategoriesStore } from "@store/categories";
import { useProducersStore } from "@store/producers";
import { useUserInfo } from "@store/userInfo";
import { useUserNotice } from "@store/userNotice";
import { useUserBalance } from "@store/userBalance";
import { useAuth } from "@controllers/services/auth";
import { useWebviewEscapeStrategy } from "@controllers/strategies/webview-escape";
import { usePWAStrategies } from "@controllers/strategies/pwa";
import { useKycStrategies } from "@controllers/strategies/kyc";
import { useIdleTitleStrategy } from "@controllers/strategies/idle-title";
import { useCashboxBonusInfoPopupStrategy } from "@controllers/strategies/cashbox";
import { useCashboxServiceWorks } from "@controllers/services/cashbox/serviceWorks";
import { usePrefetchData } from "@controllers/services/prefetch";
import { useFreshchatSurvey } from "@controllers/services/freshchat/survey";
import { useMockPopupTest } from "@modules/Mock";
import { useGiftsStoreV2 } from "@store/giftsV2";
import { useSyncActiveBonuses } from "@controllers/services/bonuses/activeBonus";
import { useShowNotificationToast } from "@controllers/services/notifications/notificationToast";
import { useBonusFeatureService } from "@controllers/services/bonuses/featureService";
import { loadStartData, promiseAll } from "@mixins/promiseMixin";
import { useServerStore } from "@store/server";
import { useSeoMetaStore } from "@store/seoMeta";
import { useLivespinsInit } from "@modules/Livespins";
import { useEnvironment } from "@store/environment";
import { useMultiLang } from "@store/multilang";
import { routeNames } from "@router/routeNames";
import { useGamesRecentController } from "@modules/GamesRecent";
import { useContacts } from "@store/projectContacts";
import { usePWA } from "@store/pwa";
import { useThemeStore } from "@store/theme";
import { useGlobalEventStatusController } from "@modules/GlobalEvent";
import { type BalanceChangedData, SocketEvent, useSocketInitService, websocketEmitter } from "@api/websockets";
import { useBannersDataService } from "@controllers/services/banners";

export default defineComponent({
    name: "App",

    components: {
        PixelAnalytics,
        ModalController,
        ToastModule,
        GameFrameController,
        LiveChat,
        FreshChat,
        PragmaticLive,
        FastTrack,
        SupportChatBadge,
        OnboardController,
        BackToTop,
    },

    setup() {
        const i18n = useI18n();
        const route = useRoute();
        const router = useRouter();
        const prefetchData = usePrefetchData();
        const { showNotificationToast } = useShowNotificationToast();
        const { loadFullUserData } = useAuth();
        const { getContext } = useContextStore();
        const userInfoStore = useUserInfo();
        const { loadRefcodeTypes } = userInfoStore;
        const cashboxServiceWorks = useCashboxServiceWorks();
        const { isBonusesEnabled } = useBonusFeatureService();
        const { initUserDataFillingModal } = useDataFillingForm();
        useLivespinsInit();
        useGamesRecentController();
        useGlobalEventStatusController();
        useSocketInitService();

        const userNoticeStore = useUserNotice();
        const giftsStore = useGifts();
        const giftsStoreV2 = useGiftsStoreV2();
        const seoMetaStore = useSeoMetaStore();
        const multiLangStore = useMultiLang();
        const contactsStore = useContacts();
        const { getEmail } = storeToRefs(contactsStore);
        const { isMockerMode, version } = storeToRefs(useEnvironment());
        const { bannersPreload } = useBannersDataService();

        const isData = ref(false);

        const seoDomain = computed(() => {
            let domain = "";

            if (seoMetaStore.hreflangList) {
                Object.entries(seoMetaStore.hreflangList).forEach(([ key, value ]) => {
                    if (value === multiLangStore.userLocale) {
                        domain = key;
                    }
                });
            }

            return domain;
        });
        const seoMeta = computed(() => seoMetaStore.seoMeta?.[router.currentRoute.value.path]);

        const canonical = computed(() => {
            if (!seoDomain.value) {
                return "";
            }

            const currentPath = router.currentRoute.value.path;
            const currentRouteName = router.currentRoute.value.name;
            const customPath = CUSTOM_CANONICAL_URLS_BY_HOST[seoDomain.value]?.[currentPath];

            if (typeof customPath === "string") {
                return `https://${seoDomain.value}${customPath}`;
            }

            if (currentRouteName === routeNames.main) {
                return `https://${seoDomain.value}`;
            }

            return `https://${seoDomain.value}${currentPath}`;
        });
        const hrefLangs = computed<Link[]>(() => {
            if (!seoMetaStore.hreflangList) {
                return [];
            }
            return Object.entries(seoMetaStore.hreflangList).map(([ domain, locale ]) => {
                let path = route.path;
                const customPath = CUSTOM_CANONICAL_URLS_BY_HOST[domain]?.[path];
                const routeName = route.name;

                if (typeof customPath === "string") {
                    path = customPath;
                }

                if (routeName === routeNames.main) {
                    path = "";
                }

                return {
                    rel: "alternate",
                    hreflang: locale,
                    href: `https://${domain}${path}`,
                };
            });
        });
        const metaDescription = computed(() => {
            let description = seoMeta.value?.metaDescription;
            if (!description && typeof document !== "undefined") {
                const metaTag: HTMLMetaElement | null = document.querySelector("meta[name=\"description\"]");
                description = metaTag?.content;
            }

            return description;
        });
        const metaRobots = computed(() => {
            if (Boolean(seoMeta.value?.isNoindexPath) || seoMetaStore.noIndex) {
                return "noindex";
            }
        });
        const metaTitle = computed(() => {
            let title = seoMeta.value?.metaTitle || null;
            if (!title && typeof document !== "undefined") {
                title = document.title;
            }

            return title;
        });
        const schemaOrg = computed(() => {
            if (route.name === routeNames.main && CONFIG_SCHEME_ORG_BY_COUNTRY[multiLangStore.country]) {
                return {
                    templateParams: {
                        schemaOrg: {
                            host: () => canonical.value,
                        },
                    },
                };
            }
        });
        const logoUrl = computed(() => {
            if (!seoDomain.value) {
                return "";
            }

            return `https://${seoDomain.value}${imageLogo}`;
        });

        useWebviewEscapeStrategy();
        usePWAStrategies();
        useFreshchatSurvey();
        useSyncActiveBonuses();
        useKycStrategies();
        useCashboxBonusInfoPopupStrategy();
        useIdleTitleStrategy();

        if (isMockerMode.value) {
            useMockPopupTest();
        }

        userInfoStore.isLoggedAsync().then((value) => {
            if (value) {
                loadFullUserData();
            }
        });

        function userNoticeUpdate(data: IUserNotificationResource) {
            const noRestrictedCountry =
                data.restricted_countries.length === 0 || restrictedNotice(data.restricted_countries);

            if (data.status === "new" && noRestrictedCountry) {
                showNotificationToast(data);

                const findNotice = userNoticeStore.userNotice.find((el) => {
                    return el.id === data.id;
                });

                if (!findNotice) {
                    userNoticeStore.addUserNotification(data);
                }
            }
        }

        function userNoticeRemove(data: string) {
            userNoticeStore.deleteUserNotification(data);
        }

        function milesNoticeUpdate(data: Record<string, unknown>) {
            showNotificationToast({
                ...(data as unknown as IUserNotificationResource),
                msg: data.notification as string,
            });
        }

        function activatePromoCode() {
            // @ts-expect-error is missing properties from type IUserNotificationResource
            showNotificationToast({ code: "PROMOCODE_ACTIVATED" });
        }

        function userLevelChanged(data: Record<string, unknown>) {
            const code = "GIFT_FOR_LEVEL_CHANGED";

            (data.gifts as Record<string, unknown>[]).forEach((gift) => {
                const vars: IUserNotificationVarsResource = {
                    giftTitle: gift.title,
                    levelName: data.levelName,
                };

                // @ts-expect-error is missing properties from type IUserNotificationResource
                showNotificationToast({
                    code,
                    vars,
                    msg: gift.title as string,
                });
            });
        }

        function reloadGifts() {
            giftsStore.loadGiftsData();
            if (isBonusesEnabled.value) {
                giftsStoreV2.loadData();
            }
        }

        function updateGifts(data: Record<string, unknown>) {
            let code = "";
            let vars: IUserNotificationVarsResource = {};
            const noteType = data.type;
            const giftsArray = data?.gifts;

            if (noteType === "gifts.activated.notify" || noteType === "gifts.auto.activated") {
                code = "GIFT_ACTIVATED_NOTIFY";
            } else if (noteType === "gifts.distributed.notify") {
                code = "GIFT_DISTRIBUTED_NOTIFY";
            }

            if (giftsArray) {
                (giftsArray as Record<string, unknown>[]).forEach((gift) => {
                    vars = {
                        giftTitle: gift.title,
                    };

                    // @ts-expect-error is missing properties from type IUserNotificationResource
                    showNotificationToast({
                        localisation: gift.localisation as Localisation,
                        code,
                        vars,
                        msg: gift.title as string,
                    });
                });
            } else if (data.notification || code) {
                vars = {
                    giftTitle: data.giftTitle,
                };

                // @ts-expect-error is missing properties from type IUserNotificationResource
                showNotificationToast({
                    msg: data.notification as string,
                    localisation: data?.localisation as Localisation,
                    code,
                    vars,
                });
            }

            reloadGifts();
        }

        async function updateUserProfile() {
            if (await userInfoStore.isLoggedAsync()) {
                await userInfoStore.loadUserProfile({ reload: true });
            }
        }

        const initData = async () => {
            const promises = loadStartData(route);

            promises[0].then((data) => {
                generateStaticRoutes(data as IStaticPagesItemResource[], router);
            });

            if (Object.keys(route.query).length > 0) {
                const expiresUtmCookies = 1000 * 60 * 60 * 8;
                setUtmParamsToCookies(route.query, expiresUtmCookies);
                setAffiliateToCookie(route.query, expiresUtmCookies);
            }

            promises.push(multiLangStore.loadLocales());
            promises.push(loadRefcodeTypes());

            await promiseAll(promises);
        };

        useHead({
            title: () => metaTitle.value,
            titleTemplate: null,
            link: () => [
                ...bannersPreload.value,
                ...hrefLangs.value,
                { rel: "icon", type: "image/png", sizes: "16x16", href: fav16 },
                { rel: "icon", type: "image/png", sizes: "32x32", href: fav32 },
                { rel: "apple-touch-startup-image", href: iosIcon180 },
                { rel: "apple-touch-icon", sizes: "152x152", href: iosIcon152 },
                { rel: "apple-touch-icon", sizes: "167x167", href: iosIcon167 },
                { rel: "apple-touch-icon", sizes: "180x180", href: iosIcon180 },
                {
                    rel: "stylesheet",
                    href: `/img/sprites/products-sprite.css${version.value ? `?${version.value}` : ""}`,
                },
                canonical.value ? { rel: "canonical", href: () => canonical.value } : {},
            ],
            meta: [
                { name: "apple-mobile-web-app-status-bar-style", content: manifest.theme_color },
                { name: "apple-mobile-web-app-title", content: manifest.short_name },
                { name: "apple-mobile-web-app-capable", content: "yes" },
                { name: "theme-color", content: manifest.theme_color },
                { name: "viewport-fit", content: "cover" },
                { name: "robots", content: () => metaRobots.value },
                { name: "description", content: () => metaDescription.value },
                metaRobots.value ? { name: "robots", content: () => metaRobots.value } : {},
            ],
            htmlAttrs: {
                lang: () => multiLangStore.userLocale,
            },
            ...schemaOrg.value,
        });

        useSeoMeta({
            ogTitle: () => metaTitle.value,
            ogLocale: () => multiLangStore.userLocale,
            ogLocaleAlternate: () => (seoMetaStore.hreflangList ? Object.values(seoMetaStore.hreflangList) : []),
            ogDescription: () => metaDescription.value,
            ogImageUrl: () => logoUrl.value,
            ogImageHeight: "40",
            ogImageWidth: "40",
            ogImageType: "image/png",
            ogImageAlt: manifest.name,
            ogType: "website",
            ogUrl: () => canonical.value,
            ogSiteName: manifest.name,
        });

        if (route.name === routeNames.main && CONFIG_SCHEME_ORG_BY_COUNTRY[multiLangStore.country]) {
            useSchemaOrg([
                defineOrganization({
                    "@id": "#organization",
                    "name": i18n.t("PROJECT_NAME"),
                    "description": SCHEME_ORG_DESCRIPTION_BY_COUNTRY[multiLangStore.country],
                    "email": getEmail.value,
                    "url": () => canonical.value,
                    "logo": () => logoUrl.value,
                    "foundingDate": "2022",
                    "sameAs": [
                        "https://www.instagram.com/winspiritcasino/ ",
                        "https://whatsapp.com/channel/0029Va6N3i64o7qUdy7KrC0b ",
                        "https://whatsapp.com/channel/0029Va6O4v311ulJb5xnqB3Q  ",
                        "https://twitter.com/WinSpiritCasino",
                    ],
                    "contactPoint": [
                        {
                            "@type": "ContactPoint",
                            "contactType": "customer support",
                            "email": getEmail.value,
                            "url": () => canonical.value,
                        },
                    ],
                }),
            ]);
        }

        watch(
            () => route.path,
            () => {
                bus.$emit("sidebar.close");
                seoMetaStore.loadSeoMeta(route);
            },
        );

        onMounted(async () => {
            if (!isData.value) {
                await initData();
                isData.value = true;
            }
            await getContext();
            cashboxServiceWorks.checkLocationHash();
            await prefetchData();

            initUserDataFillingModal();

            bus.$on("websocket.notification.send.update", userNoticeUpdate);
            bus.$on("websocket.notification.send.remove", userNoticeRemove);
            bus.$on("websocket.miles.notification", milesNoticeUpdate);
            bus.$on("websocket.activated.promocode", activatePromoCode);
            bus.$on("websocket.gifts.for.level.changed", userLevelChanged);

            bus.$on("websocket.gifts.activated.notify", updateGifts);
            bus.$on("websocket.gifts.for.level.changed", reloadGifts);
            bus.$on("websocket.gifts.auto.activated", updateGifts);
            bus.$on("websocket.gifts.state.updated", updateGifts);
            bus.$on("websocket.gifts.gift.disabled", updateGifts);
            bus.$on("websocket.gifts.distributed.notify", updateGifts);

            bus.$on("websocket.profile.changed", updateUserProfile);
        });
        onUnmounted(() => {
            bus.$off("websocket.notification.send.update", userNoticeUpdate);
            bus.$off("websocket.notification.send.remove", userNoticeRemove);
            bus.$off("websocket.miles.notification", milesNoticeUpdate);
            bus.$off("websocket.activated.promocode", activatePromoCode);
            bus.$off("websocket.gifts.for.level.changed", userLevelChanged);

            bus.$off("websocket.gifts.activated.notify", updateGifts);
            bus.$off("websocket.gifts.for.level.changed", reloadGifts);
            bus.$off("websocket.gifts.auto.activated", updateGifts);
            bus.$off("websocket.gifts.state.updated", updateGifts);
            bus.$off("websocket.gifts.gift.disabled", updateGifts);
            bus.$off("websocket.gifts.distributed.notify", updateGifts);

            bus.$off("websocket.profile.changed", updateUserProfile);
        });

        onServerPrefetch(async () => {
            await initData();
            isData.value = true;
        });
    },

    data() {
        return {
            config,
            featureFlags: getFeatureFlags(),
            serviceWorker: createServiceWorker(this.$pinia),
        };
    },

    computed: {
        ...mapState(useChats, {
            freshChatData: "fcChatData",
        }),
        ...mapState(useServerStore, {
            freshChatConfig: "freshChatConfig",
            liveChatConfig: "liveChatConfig",
        }),
        ...mapState(useUserInfo, {
            isLogged: "isLogged",
        }),
        ...mapState(useContextStore, {
            isBot: "isBot",
        }),
        ...mapState(useMultiLang, {
            userGeo: "userGeo",
        }),
        showPragmaticLive() {
            return this.featureFlags.dgaPragmatic;
        },
    },

    mounted() {
        if (this.featureFlags.dfpcEnabled && !this.isBot) {
            CoveryController.addCoveryScript();
        }

        this.loadActivePromo();
        this.loadPromoList();

        this.initCategories();
        this.initProducers();

        bus.$on("websocket.users.user.add.xp", this.setUserLevelInfo);

        bus.$on("user.data.received", this.firstLogin);

        bus.$on("websocket.treasury.event.transaction.confirmed", this.successPaymentEvent);
        bus.$on("websocket.treasury.event.payout.created", showVerificationModal);
        bus.$on("websocket.treasury.event.payout.created", this.loadWithdrawRequests);
        websocketEmitter.on(SocketEvent.BALANCE_CHANGED, this.userBalanceCommit);
        websocketEmitter.on(SocketEvent.BALANCE_CHANGED, this.showWithdrawCancelPopup);

        bus.$on("websocket.achievement.user.progress", this.updateUserAchievementsData);
        bus.$on("open-cashbox", openCashbox);

        bus.$on("websocket.miles.gift_progress", this.updateUserPromoData);

        this.serviceWorker.init(this.isBot);

        window.addEventListener("beforeinstallprompt", (event) => {
            event.preventDefault();
            this.setDeferredPWAPrompt(event);
        });

        this.setCurrency();
        this.setUserTheme();
    },

    beforeUnmount() {
        bus.$off("websocket.users.user.add.xp", this.setUserLevelInfo);
        bus.$off("user.data.received", this.firstLogin);

        bus.$off("websocket.treasury.event.transaction.confirmed", this.successPaymentEvent);
        bus.$off("websocket.treasury.event.payout.created", showVerificationModal);
        bus.$off("websocket.treasury.event.payout.created", this.loadWithdrawRequests);
        websocketEmitter.off(SocketEvent.BALANCE_CHANGED, this.userBalanceCommit);
        websocketEmitter.off(SocketEvent.BALANCE_CHANGED, this.showWithdrawCancelPopup);

        bus.$off("websocket.achievement.user.progress", this.updateUserAchievementsData);
        bus.$off("open-cashbox", openCashbox);

        bus.$off("websocket.miles.gift_progress", this.updateUserPromoData);

        this.serviceWorker.destroy();
    },

    methods: {
        ...piniaActions(usePromo, {
            loadActivePromo: "loadActivePromo",
            loadPromoList: "loadPromoList",
            updateUserPromoData: "updateUserPromoData",
        }),
        ...piniaActions(useCategoriesStore, {
            initCategories: "initCategories",
        }),
        ...piniaActions(useProducersStore, {
            initProducers: "initProducers",
        }),
        ...piniaActions(useCashbox, {
            loadWithdrawRequests: "loadWithdrawRequests",
            loadUserDepositNumbers: "loadUserDepositNumbers",
        }),
        ...piniaActions(useChats, {
            sendFreshchatRestoreId: "sendFcRestoreId",
        }),
        ...piniaActions(usePWA, {
            setDeferredPWAPrompt: "setDeferredPWAPrompt",
        }),
        ...piniaActions(useThemeStore, {
            setTheme: "setTheme",
        }),
        ...piniaActions(useUserBalance, {
            setUserCurrency: "setUserCurrency",
            setUserBalance: "setUserBalance",
        }),
        ...piniaActions(useAchievements, {
            updateUserAchievementsData: "updatePersonalizedAchievements",
        }),
        ...piniaActions(useChats, {
            freshChatSetMessageCount: "fcSetNewMessagesCount",
        }),
        ...piniaActions(useLevels, {
            setUserLevelInfo: "setUserLevelData",
        }),
        showWithdrawCancelPopup(data: BalanceChangedData) {
            withdrawCancelPopup(data);
        },

        userBalanceCommit(data: BalanceChangedData) {
            if (data.balanceType !== "real") {
                return false;
            }

            // @ts-expect-error is missing properties from type IUserBalanceResource
            const selected: IUserBalanceResource = {};

            if (typeof data.balance === "number") {
                selected.balance = data.balance;
            }

            if (typeof data.bonus === "number") {
                selected.bonus = data.bonus;
            }

            const sport = (data.bonuses as IUserBalanceResource["bonuses"])?.sport || null;
            const freeBet = (data.bonuses as IUserBalanceResource["bonuses"])?.freeBet || null;

            selected.bonuses = {
                ...selected.bonuses,
                sport,
            };

            selected.bonuses = {
                ...selected.bonuses,
                freeBet,
            };

            if (typeof data.sumWagering === "number") {
                selected.wagering = data.sumWagering;
            }

            if (typeof data.totalWagering === "number") {
                selected.totalWagering = data.totalWagering;
            }

            this.setUserBalance(selected);
        },

        firstLogin() {
            showPwaBonus(this.$route);
        },

        successPaymentEvent(transition: Record<string, unknown>) {
            DmpController.emitTransaction("deposit", transition.id);
            this.loadUserDepositNumbers();
        },

        setCurrency() {
            if (this.isLogged || !this.userGeo) {
                return;
            }

            const currency = getCurrencyByGeo(this.userGeo, this.$pinia);
            this.setUserCurrency(currency);
        },
        setUserTheme() {
            const queryTheme = this.$route.query?.theme;
            if (queryTheme) {
                this.$router.push({ query: {} });
                return this.setTheme(`theme-${queryTheme}`);
            }

            const localStorageTheme = localStorage.getItem("userTheme");
            if (localStorageTheme) {
                return this.setTheme(localStorageTheme);
            }

            return this.setTheme(this.config.theme);
        },
    },
});
</script>

<style lang="scss" src="./style.scss"></style>
