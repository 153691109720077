import { useModalsStore } from "@store/modals";
import { onMounted } from "vue";
import { useRoute } from "vue-router";

import {
    MODAL_CALLBACK,
    MODAL_CONFIRM_EXIT,
    MODAL_DEPOSIT_BONUS_BALANCE_INFO,
    MODAL_DEPOSIT_INSURANCE,
    MODAL_GAME_COOLING_OFF,
    MODAL_GAME_SUSPENDED,
    MODAL_PWA_INSTRUCTION_COPY_LINK,
    MODAL_PWA_INSTRUCTION_INSTALL_APP,
    MODAL_PWA_INSTRUCTION_IOS_SAFARI,
    MODAL_PWA_INSTRUCTION_MACOS_SAFARI,
} from "@config/modalNames";

const confirmRegistrationExit = () => import("@modules/Auth/RegistrationSteps/ConfirmRegistrationExit.vue");
const gameSuspendedModal = () => import("@modules/Game/components/SuspendedModal/SuspendedModal.vue");
const gameCoolingOffPopup = () => import("@modules/Limits/SelfExclusion/components/GameCoolingOffPopup.vue");
const depositBonusBalanceInfoPopup = () =>
    import("@modules/Popups/DepositBonusBalanceInfo/DepositBonusBalanceInfo.vue");
const depositInsuranceVerifyPopup = () =>
    import("@modules/Profile/ProfileBonuses/components/DepositInsuranceVerify.vue");

const pwaInstructionIOSSafari = () => import("@modules/Popups/PWAInstruction/PWAInstructionIOSSafari.vue");
const pwaInstructionMacSafari = () => import("@modules/Popups/PWAInstruction/PWAInstructionMacSafari.vue");
const pwaInstructionInstallApp = () => import("@modules/Popups/PWAInstruction/PWAInstructionInstallApp.vue");
const pwaInstructionCopyLink = () => import("@modules/Popups/PWAInstruction/PWAInstructionCopyLink.vue");

const callBackForm = () => import("@components/CallbackForm/CallbackForm.vue");

export function useMockPopupTest() {
    const { showModal } = useModalsStore();
    const route = useRoute();

    type PopupFunction = () => void;

    const popupList: Record<string, PopupFunction> = {
        [MODAL_CONFIRM_EXIT]: showPersonalInfoConfirmExit,
        [MODAL_GAME_SUSPENDED]: showSuspendedPopup,
        [MODAL_GAME_COOLING_OFF]: showCoolingOffPopup,
        [MODAL_DEPOSIT_BONUS_BALANCE_INFO]: showBonusInfoPopup,
        [MODAL_DEPOSIT_INSURANCE]: showDepositInsuranceVerifyPopup,
        [MODAL_PWA_INSTRUCTION_IOS_SAFARI]: showPWAInstructionIOSSafari,
        [MODAL_PWA_INSTRUCTION_MACOS_SAFARI]: showPWAInstructionMacSafari,
        [MODAL_PWA_INSTRUCTION_INSTALL_APP]: showPWAInstructionInstallApp,
        [MODAL_PWA_INSTRUCTION_COPY_LINK]: showPWAInstructionCopyLink,
        [MODAL_CALLBACK]: showCallBackForm,
    };

    async function showPWAInstructionIOSSafari() {
        const modal = await pwaInstructionIOSSafari();

        showModal({
            name: MODAL_PWA_INSTRUCTION_IOS_SAFARI,
            component: modal.default,
            new: true,
        });
    }
    async function showPWAInstructionMacSafari() {
        const modal = await pwaInstructionMacSafari();

        showModal({
            name: MODAL_PWA_INSTRUCTION_MACOS_SAFARI,
            component: modal.default,
            new: true,
        });
    }
    async function showPWAInstructionInstallApp() {
        const modal = await pwaInstructionInstallApp();

        showModal({
            name: MODAL_PWA_INSTRUCTION_INSTALL_APP,
            component: modal.default,
            new: true,
        });
    }
    async function showPWAInstructionCopyLink() {
        const modal = await pwaInstructionCopyLink();

        showModal({
            name: MODAL_PWA_INSTRUCTION_COPY_LINK,
            component: modal.default,
            new: true,
        });
    }

    async function showPersonalInfoConfirmExit() {
        const modal = await confirmRegistrationExit();

        showModal({
            name: MODAL_CONFIRM_EXIT,
            component: modal.default,
            new: true,
            props: {
                userData: {},
                stepReg: "mock",
            },
        });
    }

    async function showSuspendedPopup() {
        const modal = await gameSuspendedModal();

        showModal({
            name: MODAL_GAME_SUSPENDED,
            component: modal.default,
            new: true,
            props: {},
        });
    }

    async function showCoolingOffPopup(mainPageButton?: boolean) {
        const module = await gameCoolingOffPopup();

        showModal({
            new: true,
            component: module.default,
            name: MODAL_GAME_COOLING_OFF,
            showFirst: true,
            props: {
                mainPageButton,
            },
        });
    }

    async function showBonusInfoPopup() {
        const module = await depositBonusBalanceInfoPopup();

        showModal({
            name: MODAL_DEPOSIT_BONUS_BALANCE_INFO,
            component: module.default,
            new: true,
        });
    }
    async function showDepositInsuranceVerifyPopup() {
        const module = await depositInsuranceVerifyPopup();

        const isEmailVerified = false;
        const isIdentityVerified = false;
        const isLastTransaction = false;
        const isSuccessDepositToday = false;

        showModal({
            name: MODAL_DEPOSIT_INSURANCE,
            component: module.default,
            new: true,
            props: {
                isEmailVerified,
                isIdentityVerified,
                isLastTransaction,
                isSuccessDepositToday,
            },
        });
    }

    async function showCallBackForm() {
        const modal = await callBackForm();

        showModal({
            name: MODAL_CALLBACK,
            component: modal.default,
            mobileFriendly: true,
        });
    }

    onMounted(() => {
        if (route.hash) {
            const hash = route.hash.replace("#", "");
            const popup = popupList[hash];

            if (popup) {
                popup();
            }
        }
    });
}
